import React, { useState } from "react";
import LinkedIn from "../../../../public/img/event_speaker_section/LinkedIn.svg";
import facebook from "../../../../public/img/event_speaker_section/facebook.svg";
import twitter from "../../../../public/img/event_speaker_section/twitter-logo-white.svg";
import website from "../../../../public/img/event_speaker_section/website.svg";
import ProSideViewModal from "../GeneralComponents/ProSideViewModal";

const SpeakerCardPro = ({ speaker }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div
        className="speaker-card mx-0 mx-sm-4 mx-md-3 my-3 d-flex flex-column"

      >
        <div className="image-container mx-auto" onClick={() => setOpenModal((prev) => !prev)}>
          <img src={speaker.image_url} className="speaker-image" alt="" />
        </div>
        <div className="ms-2">
          <div className="mb-2" onClick={() => setOpenModal((prev) => !prev)}>

            <p className="name mb-0">{speaker.name}</p>
            {speaker.designation && (
              <p className="desc mb-1">{speaker.designation}</p>
            )}
            {speaker.organisation && (
              <p className="org mb-1">{speaker.organisation}</p>
            )}
          </div>
          {!speaker?.linkedin_url &&
            !speaker?.facebook_url &&
            !speaker?.twitter_url &&
            !speaker?.website_url ? (
            <div className="pb-4"></div>
          ) : (
            <div className="d-flex flex-row mt-3">
              {speaker?.facebook_url && (
                <div className="facebook_wrapper" style={{ marginRight: 12 }}>
                  <div onClick={() => window.open(speaker.facebook_url)}>
                    <img className="facebook" src={facebook} alt={"facebook"} />
                  </div>
                </div>
              )}
              {speaker?.twitter_url && (
                <div className="twitter_wrapper" style={{ marginRight: 12 }}>
                  <div onClick={() => window.open(speaker.twitter_url)}>
                    <img className="twitter" src={twitter} alt={"twitter"} />
                  </div>
                </div>
              )}
              {speaker?.linkedin_url && (
                <div style={{ marginRight: 12 }}>
                  <div onClick={() => window.open(speaker.linkedin_url)}>
                    <img className="linkedIn" src={LinkedIn} alt={"Linked In"} />
                  </div>
                </div>
              )}
              {speaker?.website_url && (
                <div className="twitter_wrapper">
                  <div onClick={() => window.open(speaker.website_url)}>
                    <img className="website" src={website} alt={"website"} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ProSideViewModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        background="#F5F5F5"
        className="overflow-scroll"
      >
        <div className="p-sm-4 py-4 px-3 card-box">
          <div className="text-end">
            <button
              onClick={() => setOpenModal(false)}
              className="border-0"
              style={{
                background: "#F5F5F5",
                color: "#525252",
              }}
            >
              <i className="fa-solid fa-xmark" style={{ fontSize: "18px" }}></i>
            </button>
          </div>
          <div className="d-flex mb-4 speaker-box">
            <div className="organiser-image-cont-2 rounded-circle me-3">
              <img
                src={speaker.image_url}
                style={{ width: "180px" }}
                alt={`${speaker.name} poster image`}
                className="speaker-image2"
              />
            </div>
            <div className="d-flex flex-column speaker-about ">
              <p className="speaker-name">{speaker.name}</p>
              <p className="speaker-designation">{speaker.designation}</p>
              <p className="speaker-organisation">{speaker.organisation}</p>
              {speaker.location && (
                <p className="speaker-organisation gap-2 d-flex">
                  <img src="/img/prolocation.svg" alt="" />
                  {speaker.location}</p>
              )}

              <div className="d-flex flex-row">
                {speaker.facebook_url && (
                  <div
                    className="me-2 cursor-pointer"
                    onClick={() => window.open(speaker.facebook_url)}
                  >
                    <img src="/img/facebook02.svg" alt="" />
                  </div>
                )}
                {speaker.twitter_url && (
                  <div
                    className="me-2"
                    onClick={() => window.open(speaker.twitter_url)}
                  >
                    <img src="/img/twitter-orange.svg" alt="" />
                  </div>
                )}
                {speaker.linkedin_url && (
                  <div
                    className="me-2"
                    onClick={() => window.open(speaker.linkedin_url)}
                  >
                    <img src="/img/linkedin02.svg" alt="" />
                  </div>
                )}
                {speaker.website_url && (
                  <div
                    className="me-2"
                    onClick={() => window.open(speaker.website_url)}
                  >
                    <img src="/img/Website02.svg" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {speaker.about &&
            <p className="mb-0 desc" onClick={(e) => e.stopPropagation()}
              dangerouslySetInnerHTML={{ __html: `${speaker.about?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` }}
            />
          }
          {speaker.tags && speaker.tags.length > 0 && (
            <div className="speaker-tags">
              <p className="my-3 tag-header">Tags</p>
              <div className="d-flex flex-wrap my-3">
                {speaker.tags.map((tag) => (
                  <div key={tag.id} className="me-2 mb-2 tag-container">
                    <span className="tag-name">#{tag.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}



        </div>
      </ProSideViewModal>
      <style jsx>{`
        .me-2{
          cursor: pointer;
        }
        .speaker-card {
          cursor: pointer;
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          width: 273px;
          height: auto;
          max-width: 273px;
          //padding: 4px;
          border-radius: 15px;
        }
        .card-box {
          height: 456px;
          overflow: scroll;
          width: 100%;
        }
        .speaker-box {
          gap: 20px;
        }
        .speaker-about {
          gap: 10px;
        }
        .speaker-name {
          font-family: "Prompt";
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #fdb14b;
        }
        .speaker-designation {
          font-family: "Prompt";
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;

          color: #212529;
        }
        .speaker-organisation {
          font-family: "Prompt";
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #212529;
        }
        .icon {
          max-width: 17px;
          margin-top: -25px;
          cursor: pointer;
          -webkit-filter: invert(100%); /* Safari/Chrome */
          filter: invert(100%);
        }

        .speaker-image {
          object-fit: cover;
          width: 100%;
          height: 265px;
          border-radius: 5% 5% 0% 0% !important;
          object-fit: cover;
          width: 100%;
          height: 265px;
          border-bottom: 2px solid #cacaca;
        }
        .image-container {
          width: 100%;
          border-radius: 5px !important;
        }
        .name {
          font-family: "Prompt";
          font-style: normal;
          font-weight: 700;
          margin-top: 5px;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.04em;
          color: #141b25;
        }

        .linkedIn,
        .facebook,
        .twitter,
        .website {
          width: 19px;
          -webkit-filter: invert(100%); /* Safari/Chrome */
          filter: invert(100%);
          margin-top: -19px;
        }
        .desc {
          font-family: "Prompt" !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #212529;
          overflow-wrap: break-word;
        }
        .desc p{
          color: #212529;
        }

        .org {
          font-family: "Hind";
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #212529;
        }
        .tag-header{
          font-family: "Prompt";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #141B25;
        }
        .tag-container{
          background-color: #FDB14B14;
          height: 28px;
          padding: 0px 12px 0px 12px;
          border-radius: 4px;
        }
        .tag-name{
          font-family: "Hind";
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #212529;
        }
        @media (max-width: 768px) {
          .card-box {
            overflow: scroll;
            height: 630px !important;
            width: 374px !important;
          }
          .speaker-box {
            flex-direction: column;
            align-items: start !important;
          }
          .speaker-about {
            font-size: 14px !important;
            line-height: 20px !important;
          }
          organiser-image-cont-2 {
            width: 120px !important;
          }
          .speaker-image2 {
            width: 120px !important;
            border-radius: 4px;
          }
          .speaker-name {
            font-family: "Prompt";
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #fdb14b;
          }
          .speaker-designation {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .speaker-organisation {
            font-family: "Prompt";
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .desc {
            font-family: "Prompt" !important;
            font-weight: 400;
            font-size: 14px;
            overflow-wrap: break-word;
          }
        }
          @media (max-width: 576px) {
            .desc {
             font-size: 14px;
             
            }
            .speaker-card {
              width: 100%;
              max-width: 100%;
            }
            .speaker-about {
              gap: 5px;
            }
            .image-container img{
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
            .speaker-card{
              border-radius: 15px;
              padding: 12px;
            }
            .speaker-image{
              border-bottom:0px;
            }

          }
      `}</style>
    </>
  );
};

export default SpeakerCardPro;