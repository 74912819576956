import React from 'react'

const SoldOutPro = () => {
    return (
        <>
            <div className="sold-out-btn d-flex align-items-center justify-content-center px-4 py-2">
                <span className="">Sold Out</span>
            </div>
            <style jsx>{`
                .sold-out-btn{           
                border-radius: 5px;
                background: #CCCCCC;
                    box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
                   
                    // border-radius: 20px 20px 50px 40px;
                    // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
                }
                .sold-out-btn span{
                    font-family: Prompt;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #666666;
                }
                 @media only screen and (max-width: 576px) {
                .sold-out-btn span{
                    font-size: 14px !important;
                    font-weight: 600 !important;
                    }
                }
                `}
            </style>
        </>
    )
}

export default SoldOutPro
