import React from 'react';
import Link from "next/link";
import Image from "next/image";
import { hostEventUrl } from '../../../config/config';

const EventFooterPro = () => {
    return (
        <>
            <div className="container-fluid footer px-0">
                <div className='row'>
                    <div className='col-lg-3 d-flex justify-content-center mt-5 align-items-center'>
                        <Image src="/logos/konfhub-icon-white.svg" className='konficon' alt="" width={100} height={100} />
                    </div>
                    <div className='col-lg-3 more d-flex flex-column mt-5 pt-4'>
                        <span className='headings text-lg-start text-center'>More on Konfhub</span>
                        <Link href={`${hostEventUrl}?host_event=true`}>
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Hosting
                            </div>
                        </Link>

                        <Link href="/pricing">
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Pricing
                            </div>
                        </Link>

                        <Link href="mailto:reachus@konfhub.com" target="_blank">
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Contact Us
                            </div>
                        </Link>

                        <Link href="/logos">
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Brand Assets
                            </div>
                        </Link>

                    </div>
                    <div className='col-lg-3 more d-flex flex-column mt-5 pt-4'>
                        <span className='headings text-lg-start text-center'>Quick Links</span>

                        <Link href="https://quiz.konfhub.com/" >
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                QuizHub

                            </div>
                        </Link>

                        <Link href="/communities">
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Community
                            </div>
                        </Link>

                        {/* <Link href="https://konfhub.freshteam.com/jobs">
                            <a className="my-1 text-lg-start mx-au w-fit text-center">
                                Careers
                            </a>
                        </Link> */}

                        <Link href="/awards">
                            <div className="my-1 text-lg-start mx-au w-fit text-center">
                                Awards
                            </div>
                        </Link>

                    </div>
                    <div className='col-lg-3 more d-flex flex-column mt-5 pt-4'>
                        <span className='headings text-lg-start text-center'>We are compliant with</span>

                        <div className='logo-class'>
                            <Image
                                src="/img/gpdr-logo.png"
                                className="mt-3 "
                                alt="Konfhub Logo"
                                width={100}
                                height={100}

                            />
                        </div>
                    </div>
                </div>
                <div className='row mt-5 mb-5 pb-5'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-12 text-center '>
                        <a
                            href="https://www.facebook.com/konfhub/"
                            rel="noreferrer"
                            target="_blank"
                            className="me-2 icons"
                        >
                            <i className="fab fa-facebook-square"></i>
                        </a>
                        <a
                            href="https://twitter.com/konfhub"
                            rel="noreferrer"
                            target="_blank"
                            className="mx-2 icons"
                        >
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a
                            href="https://www.linkedin.com/in/konfhub/"
                            rel="noreferrer"
                            target="_blank"
                            className="mx-2 icons"
                        >
                            <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                    <div className="col-12 mt-3 text-center policies">
                        <Link href="/code-of-conduct">
                            <div className="my-2 text-lg-start text-center">
                                Code of Conduct
                            </div>
                        </Link>{" "}
                        |{" "}
                        <Link href="/terms-and-conditions">
                            <div className="my-2 text-lg-start text-center">
                                Terms &amp; Conditions
                            </div>
                        </Link>{" "}
                        |{" "}
                        <Link href="/privacy-policy">
                            <div className="my-2 text-lg-start text-center">
                                Privacy Policy
                            </div>
                        </Link>{" "}
                        |{" "}
                        <Link href="/cookie-policy">
                            <div className="my-2 text-lg-start text-center">
                                Cookie Policy
                            </div>
                        </Link>
                        {" "}|{" "}
                        <Link href="/cancellation-refund-policy">
                            <div className="my-2 text-lg-start text-center">
                                Cancellation & Refund Policy
                            </div>
                        </Link>
                        {" "}|{" "}
                        <Link href="/responsible-disclosure">
                            <div className="my-2 text-lg-start text-center">
                                Responsible Disclosure
                            </div>
                        </Link>
                    </div>
                    <div className='col-12 mt-3 mb-1 text-center d-flex justify-content-center'>
                        <p className='konf'>© KonfHub Technologies LLP  | 2019-{new Date().getFullYear()}. All Rights Reserved</p>
                    </div>

                </div>

            </div>
            <style jsx>{`
                    .footer {
                        background-image: url("/img/footer-pro.svg");
                        background-color: #cccccc;
                        height: 100%;
                    }
                    .headings{
                        font-family: 'Nunito';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 20px;
                        line-height: 200%;
                        /* or 40px */
                        color: #FFFFFF;
                    }
                    .icons{
                        font-size: 21px;
                    }
                    .konf{
                        max-width:380px;
                        font-family: Nunito;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #ffffff;
                    }

                    a{
                        font-family: 'Nunito';
                        font-size: 16px;
                        font-weight: 300;
                        text-decoration: none;
                        color: white !important;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                    .policies{
                        font-family: Nunito;
                        font-size: 14px;
                        color: white;
                    }
                    .logo-class{
                           margin-left: 55px;
                        }
                    .konficon{
                        height: 100px;
                        width: auto;
                    }
                    .w-fit{
                        width: fit-content !important;
                    }

                    @media only screen and (max-width: 992px){
                        .logo-class{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                           margin-left: 0px;

                        }
                        .mx-au{
                        margin-right: auto!important;
                        margin-left: auto!important;
                    }

                `}</style>
        </>
    )
}

export default EventFooterPro
