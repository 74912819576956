import React from 'react'

const ComingSoonPro = () => {
    return (
        <>
        <div className="coming-soon-btn d-flex align-items-center justify-content-center px-4 py-2">
            <span className="">Coming Soon</span>
        </div>
        <style jsx>{`
            .coming-soon-btn{
                background: #ccc;
                box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                // border-radius: 20px 20px 50px 40px;
                // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
            }
            .coming-soon-btn span{
                font-family: Prompt;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                color: rgba(251, 88, 80, 1);
            }
            @media only screen and (max-width: 786px){
                .coming-soon-btn{
                    position: relative;
                    top: 15px;
                }
            }
            @media only screen and (max-width: 576px){
                .coming-soon-btn{
                    top: 0px;
                }
            }
                .coming-soon-btn span{
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            `}
        </style>
        </>
    )
}

export default ComingSoonPro
