import React from 'react'
import EventLogoPro from './EventLogoPro'
import EventStatsPro from './EventStatsPro'
import EventTitlePro from './EventTitlePro'

const EventHeadPro = ({ eventUrl, hideHighlight, currentUrl, buttonText, hasCoupons, ticketData }) => {
    return (
        <>
            <div className="d-flex flex-column position-relative">
                <div className="cont row mx-sm-5 mx-4  mt-lg-5 mt-0 justify-content-around">
             
                    <EventTitlePro 
                        eventUrl={eventUrl} 
                        currentUrl={currentUrl} 
                        buttonText={buttonText} 
                        hasCoupons={hasCoupons} 
                        ticketData={ticketData}
                    />
                       <EventLogoPro />
                    
                </div>
                {/* {hideHighlight !== true && (<EventStatsPro eventUrl={eventUrl} />)} */}
            </div>
            <style jsx> {`
     
    
        @media only screen and (max-width: 576px){
            .cont{
                flex-direction:column-reverse
             
            }
          
        }
        `}
            </style>
        </>
    )
}

export default EventHeadPro